<template>
	<standard-container title="dashboard" icon="mdi-home" show-buttons nocard>
		<template v-slot:buttons>
			<div class="d-flex justify-end self-align-baseline mb-2">
				<v-btn x-small class="text-right" :disabled="!statShow" :loading="!statShow" icon title="Aggiorna le statistiche" @click="refreshStat">
					<v-icon>mdi-refresh</v-icon>
				</v-btn>
			</div>
		</template>

		<template v-slot:footer>
			<!--
				todo: area per avvisare gli admin di qualcosa (ad esempio nuove richieste di formazione)

			<v-row v-if="admin && adminAlert">
				<v-col cols="12" lg="12">
					fdsfsd
				</v-col>
			</v-row>
			-->
			<v-row>
				<v-col v-if="statShow && statUltimeValutazioni && statUltimeValutazioni.length>0" cols="12" lg="6">
					<v-card>
						<v-card-title><translated-text>Ultime valutazioni effettuate</translated-text></v-card-title>
						<v-card-text>
							<v-list>
								<v-list-item v-for="item in statUltimeValutazioni" :key="item.Id" @click="openPersona(item.Persona.Id)">
									<v-list-item-avatar>
										<v-avatar :size="35">
											<img v-if="item.Persona.Immagine" alt="Avatar" :src="imagesPathPerson + item.Persona.Immagine + '?width=70&height=70&mode=crop&anchor=top'">
											<avatar v-else :username="item.Persona.FullName" :size="35" />
										</v-avatar>
									</v-list-item-avatar>
									<v-list-item-content>
										<v-list-item-title>{{ item.Persona.FullName }}</v-list-item-title>
										<v-list-item-subtitle>{{ item.Competenza.NomeCompetenza }}</v-list-item-subtitle>
									</v-list-item-content>
									<v-list-item-action>
										<v-list-item-action-text>{{ gStandardDate(item.DataValutazione) }}</v-list-item-action-text>
										<v-chip x-small :color="item.ColoreValutazione">
											{{ item.DescrizioneValutazione }}
										</v-chip>
									</v-list-item-action>
								</v-list-item>								
							</v-list>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col v-if="statShow && statUltimeValutazioni && statUltimeValutazioni.length>0" cols="12" lg="6">
					<v-card>
						<v-card-title><translated-text>Ultime valutazioni negative</translated-text></v-card-title>
						<v-card-text>
							<v-list>
								<v-list-item v-for="item in statValutazioniNegative" :key="item.Id" @click="openPersona(item.Persona.Id)">
									<v-list-item-avatar>
										<v-avatar :size="35">
											<img v-if="item.Persona.Immagine" alt="Avatar" :src="imagesPathPerson + item.Persona.Immagine + '?width=70&height=70&mode=crop&anchor=top'">
											<avatar v-else :username="item.Persona.FullName" :size="35" />
										</v-avatar>
									</v-list-item-avatar>
									<v-list-item-content>
										<v-list-item-title>{{ item.Persona.FullName }}</v-list-item-title>
										<v-list-item-subtitle>{{ item.Competenza.NomeCompetenza }}</v-list-item-subtitle>
									</v-list-item-content>
									<v-list-item-action>
										<v-list-item-action-text>{{ gStandardDate(item.DataValutazione) }}</v-list-item-action-text>
										<span class="d-flex caption"><v-chip x-small :color="item.ColoreValutazione">{{ item.DescrizioneValutazione }}</v-chip> / {{ item.DescrizioneLivelloAtteso }}</span>
									</v-list-item-action>
								</v-list-item>								
							</v-list>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col v-if="statShow && statCompetenzeConPiùValutazioni && statCompetenzeConPiùValutazioni.length>0" cols="12" lg="6">
					<v-card>
						<v-card-title><translated-text>Competenze con più valutazioni</translated-text></v-card-title>
						<v-card-text>
							<v-simple-table dense>
								<template v-slot:default>
									<tbody>
										<tr v-for="(item, index) in statCompetenzeConPiùValutazioni" :key="index" @click="openPersona(item.Persona.Id)">
											<td>
												<standard-label>{{ item.Competenza.NomeCompetenza }}</standard-label>
											</td>
											<td>
												<standard-label>{{ item.NumeroValutazioni }}</standard-label>
											</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col v-if="statShow && statCompetenzeConMenoValutazioni && statCompetenzeConMenoValutazioni.length>0" cols="12" lg="6">
					<v-card>
						<v-card-title><translated-text>Competenze con meno valutazioni</translated-text></v-card-title>
						<v-card-text>
							<v-simple-table dense>
								<template v-slot:default>
									<tbody>
										<tr v-for="(item, index) in statCompetenzeConMenoValutazioni" :key="index" @click="openPersona(item.Persona.Id)">
											<td>
												<standard-label>{{ item.Competenza.NomeCompetenza }}</standard-label>
											</td>
											<td>
												<standard-label>{{ item.NumeroValutazioni }}</standard-label>
											</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col v-if="statShow && statValutazioniMancanti && statValutazioniMancanti.length>0" cols="12" lg="6">
					<v-card>
						<v-card-title><translated-text>Valutazioni mancanti</translated-text></v-card-title>
						<v-card-text>
							<v-simple-table>
								<template v-slot:default>
									<tbody>
										<tr v-for="(item, index) in statValutazioniMancanti" :key="index" @click="openPersona(item.Persona.Id)">
											<td style="width:60px">
												<v-avatar :size="35">
													<img v-if="item.Persona.Immagine" alt="Avatar" :src="imagesPathPerson + item.Persona.Immagine + '?width=70&height=70&mode=crop&anchor=top'">
													<avatar v-else :username="item.Persona.FullName" :size="35" />
												</v-avatar>
											</td>
											<td>
												<standard-label cssclass="body-1">
													{{ item.Persona.FullName }}
												</standard-label>
												<standard-label>{{ item.Competenza.NomeCompetenza }}</standard-label>
											</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" lg="6">
					<v-card v-if="setup.EnableDashboardTodoSystem">
						<v-card-title><translated-text>Personal todo list</translated-text></v-card-title>
						<v-card-text>
							<todo-list />
						</v-card-text>
					</v-card>
				</v-col>
				<v-col v-if="calendarioCorsi!=null && calendarioCorsi!={} && calendarioCorsi.Eventi && calendarioCorsi.Eventi.length>0" cols="12">
					<v-card>
						<v-card-text>
							<hr-calendario-corsi :value="calendarioCorsi" />
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</template>

		<template v-slot:debug>
			<v-row>
				<v-col cols="12" lg="6">
					<debug-panel label="setup">
						{{ setup }}
					</debug-panel>
				</v-col>
				<v-col cols="12" lg="6">
					<debug-panel label="keyvalues">
						{{ keyvalues }}
					</debug-panel>
				</v-col>
				<v-col cols="12" lg="6">
					<debug-panel label="calendarioCorsi">
						{{ calendarioCorsi }}
					</debug-panel>
				</v-col>
				<v-col cols="12" lg="6">
					<debug-panel label="currentUser">
						{{ currentUser }}
					</debug-panel>
				</v-col>
			</v-row>
		</template>
	</standard-container>
</template>

<script>
import StandardContainer from '@/components/standard-container'
import TodoList from '@/components/todo-list'
import DebugPanel from '@/components/debug-panel'
import Avatar from 'vue-avatar'
import StandardLabel from '@/components/standard-label'
import HrCalendarioCorsi from '@/components/hr-calendario-corsi'

import moment from 'moment'
// import DebugContainer from '@/components/debug-container'
export default {
	name: 'DashboardPage',
	components: { StandardContainer, TodoList, DebugPanel, Avatar, StandardLabel, HrCalendarioCorsi },
	data: () => ({
	}),
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		setup() { return this.$store.getters['setup'] },
		keyvalues() { return this.$store.getters['keyvalues'] },
		currentUser() { return this.$store.getters['currentUser'] },
		// stat
		imagesPathPerson() { return this.$store.getters['imagesPathPerson'] },
		decodifiche() { return this.$store.getters['decodifiche'] },
		persone() { return this.$store.getters['persone'] },
		competenze() { return this.$store.getters['competenze'] },
		stat() { return this.$store.getters['stat'] },
		statShow() { return !!this.stat && !!this.stat.UltimeValutazioni },
		statUltimeValutazioni() {
			if(this.statShow && this.stat.UltimeValutazioni){
				var valutazioni = JSON.parse(JSON.stringify(this.stat.UltimeValutazioni))
				valutazioni.forEach(item => {
					item.DescrizioneValutazione = this.decodifiche.LivelliCompetenza['Livello'+item.CodiceValutazione]
					item.ColoreValutazione = this.decodifiche.LivelliCompetenza['Colore'+item.CodiceValutazione]
					item.DescrizioneLivelloAtteso = ''
					item.ColoreLivelloAtteso = ''
					if (item.LivelloAtteso && item.LivelloAtteso > 0) {
						item.DescrizioneLivelloAtteso = this.decodifiche.LivelliCompetenza['Livello'+item.LivelloAtteso]
						item.ColoreLivelloAtteso = this.decodifiche.LivelliCompetenza['Colore'+item.LivelloAtteso]
					}
					item.Persona = this.persone.find(p => {return p.Id === item.IdPersona})
					item.Job = this.decodifiche.Jobs.find(j => {return j.CodiceJob === item.CodiceJob})
					item.Competenza = this.competenze.find(c => {return c.CodiceCompetenza === item.CodiceCompetenza})
				})
				return valutazioni
			}
			return null
		},
		statValutazioniNegative() {
			if(this.statShow && this.stat.ValutazioniNegative){
				var valutazioni = JSON.parse(JSON.stringify(this.stat.ValutazioniNegative))
				valutazioni.forEach(item => {
					item.DescrizioneValutazione = this.decodifiche.LivelliCompetenza['Livello'+item.CodiceValutazione]
					item.ColoreValutazione = this.decodifiche.LivelliCompetenza['Colore'+item.CodiceValutazione]
					item.DescrizioneLivelloAtteso = ''
					item.ColoreLivelloAtteso = ''
					if (item.LivelloAtteso && item.LivelloAtteso > 0) {
						item.DescrizioneLivelloAtteso = this.decodifiche.LivelliCompetenza['Livello'+item.LivelloAtteso]
						item.ColoreLivelloAtteso = this.decodifiche.LivelliCompetenza['Colore'+item.LivelloAtteso]
					}
					item.Persona = this.persone.find(p => {return p.Id === item.IdPersona})
					item.Job = this.decodifiche.Jobs.find(j => {return j.CodiceJob === item.CodiceJob})
					item.Competenza = this.competenze.find(c => {return c.CodiceCompetenza === item.CodiceCompetenza})
				})
				return valutazioni
			}
			return null
		},
		statValutazioniMancanti() {
			if(this.statShow && this.stat.ValutazioniMancanti){
				var items = JSON.parse(JSON.stringify(this.stat.ValutazioniMancanti))
				items.forEach(item => {
					item.Persona = this.persone.find(p => {return p.Id === item.Id})
					item.Job = this.decodifiche.Jobs.find(j => {return j.CodiceJob === item.CodiceJob})
					item.Competenza = this.competenze.find(c => {return c.CodiceCompetenza === item.CodiceCompetenza})
				})
				return items
			}
			return null
		},
		statCompetenzeConPiùValutazioni() {
			if(this.statShow && this.stat.CompetenzeConPiùValutazioni){
				var items = JSON.parse(JSON.stringify(this.stat.CompetenzeConPiùValutazioni))
				items.forEach(item => {
					item.Competenza = this.competenze.find(c => {return c.CodiceCompetenza === item.CodiceCompetenza})
				})
				return items
			}
			return null
		},
		statCompetenzeConMenoValutazioni() {
			if(this.statShow && this.stat.CompetenzeConMenoValutazioni){
				var items = JSON.parse(JSON.stringify(this.stat.CompetenzeConMenoValutazioni))
				items.forEach(item => {
					item.Competenza = this.competenze.find(c => {return c.CodiceCompetenza === item.CodiceCompetenza})
				})
				return items
			}
			return null
		},
		calendarioCorsi() { return this.$store.getters['calendarioCorsi'] },
	},
	methods: {
		openPersona(idPersona) {
			var persona = this.persone.find(p => {return p.Id === idPersona})
			if(persona)	this.$store.dispatch('editPersona',  JSON.parse(JSON.stringify(persona)))
		},
		refreshStat() {
			var annoCorrente = parseInt(moment().format('YYYY'))
			this.$store.dispatch('loadStat', annoCorrente).then(() => {
			})
		}
	}
}
</script>
