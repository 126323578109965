<template>
	<v-list two-line subheader>
		<v-list-item>
			<v-text-field id="newTodo" v-model="newTodo" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" clearable name="newTodo" label="Nuovo todo" @keyup.enter="addTodo" />
			<v-btn class="ml-5" small color="warning" @click="addTodo">
				add
			</v-btn>
		</v-list-item>
		<template v-for="(item, i) in todos">
			<v-list-item :key="i">
				<v-list-item-action>
					<v-checkbox v-model="item.done" color="warning" @change="saveTodos" />
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title :class="{ done: item.done }">
						{{ item.title }}
					</v-list-item-title>
					<v-list-item-subtitle v-if="item.createDate">
						Added on: {{ gStandardDate(item.createDate) }}
					</v-list-item-subtitle>
				</v-list-item-content>
				<v-btn v-if="item.done" icon ripple color="warning" @click="removeTodo(i)">
					<v-icon class="white--text">
						mdi-close
					</v-icon>
				</v-btn>
			</v-list-item>
		</template>
	</v-list>
</template>

<script>
/**********************************************************************************************
**** componente todo list che salva nella keyvalues *******************************************
***********************************************************************************************
*/
import moment from 'moment'
export default {
	name: 'TodoList',
	data () {
		return {
			isDark:true,
			show: true,
			newTodo: '',
			todos: [],
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		tce() { return JSON.parse(JSON.stringify(this.$store.getters['tipologiaCampiEdit'])) },
		currentUser() { return this.$store.getters['currentUser'] },
		keyvalues() { return this.$store.getters['keyvalues'] },
		startTodos() { return this.$store.getters['keyvalues'] ? ( this.$store.getters['keyvalues']['todo'] ? JSON.parse(this.$store.getters['keyvalues']['todo']) : [] ) : [] },
	},
	mounted() {
		this.todos = this.startTodos
	},
	methods: {
		addTodo() {
			var value = this.newTodo && this.newTodo.trim()
			if (!value) {
				return
			}
			this.todos.unshift({
				title: this.newTodo,
				createDate: moment(),
				done: false
			})
			this.newTodo = ''
			this.saveTodos()
		},
		removeTodo(index) {
			this.todos.splice(index, 1)
			this.saveTodos()
		},
		saveTodos() {
			var ukv = {
				UserId: this.currentUser.Codice,
				Key: 'todo',
				Value: JSON.stringify(this.todos)
			}
			this.$store.dispatch('setUserKeyvalue', ukv)
		}
	},
}
</script>

<style scoped lang="less">
.done {
  text-decoration: line-through;
}
</style>